<template>
  <div class="form-group">
    <label :for="$attrs.name" class="form__label">{{label}}</label>
    <select class="form-control form__input"
            :name="$attrs.name"
            :id="$attrs.name"
            v-bind="$attrs"
            v-model="innerValue"
            :disabled="disabled"
    >
      <slot />
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectInputForms",
  props: {
    vid: {
      type: String,
    },
    label: {
      type: String,
    },

    classes: {
      type: String,
    },
    change: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    displayed: {
      type: Boolean,
      required: false
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
}
</script>

<style scoped>

</style>