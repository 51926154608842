<template>
    <div class="registration">
        <div class="registration__logo">
            <div class="container container-resize-2">
                <img src="images/logo.png" alt="logo" class="registration__logo--img">
            </div>
        </div>

        <div class="registration__title mt-5">
            <div class="container container-resize-2">
                <h1 class="registration__title--main">Registration Form</h1>
                <h2 class="registration__title--sub">Please fill out this section correctly.</h2>
            </div>
        </div>

        <div class="registration__detail mt-5">
            <div class="container container-resize-2">
                <h1>Personal Details</h1>
            </div>
        </div>

        <div class="container container-resize-2 mt-5">
            <div class="registration__text">
                <p>Mandatory</p>
            </div>

            <div class="row mt-5">
                <div class="col-md-6">
                    <div class="form-group form__group--1 radio">
                        <label for="individual" class="form__label">Individual</label>
                        <input type="radio" id="individual" class="radio__input" name="corporate">
                    </div>

                    <div class="form-group form__group--1">
                        <label for="corporate" class="form__label">Corporate</label>
                        <input type="radio" id="corporate" class="radio__input" name="corporate">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="reg" class="form__label">Registration Date</label>
                        <input type="date" class="form-control form__input margin-bottom " id="reg">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="customer" class="form__label">Customer Name</label>
                        <input type="text" class="form-control form__input margin-bottom" id="customer">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="business" class="form__label">Business Name</label>
                        <input type="text" class="form-control form__input form__input--small margin-bottom" id="business">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="type" class="form__label">Business Type</label>
                        <select class="form-control form__input" id="type">
                            <option>Retiree</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="gender" class="form__label">Gender</label>
                        <select class="form-control form__input form__input--small" id="gender">
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="means" class="form__label">Means of Identification</label>
                        <select class="form-control form__input margin-bottom" id="means">
                            <option>Certificate</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="birth" class="form__label">Date of Birth</label>
                        <input type="date" class="form-control form__input form__input--small" id="birth">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="middle" class="form__label">Email</label>
                        <input type="email" class="form-control form__input margin-bottom" id="middle" placeholder="example@gmail.com">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="code" class="form__label">Country Code</label>
                        <select class="form-control form__input form__input--small" id="code">
                            <option>(+234)</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="phone" class="form__label">Phone Number</label>
                        <input type="tel" class="form-control form__input margin-bottom" id="phone">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="contact" class="form__label">Contact Address</label>
                        <textarea class="form-control form__input margin-bottom" id="contact" rows="8"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="nearest" class="form__label">Landmark: Nearest bus stop to your address</label>
                        <input type="text" class="form-control form__input margin-bottom" id="nearest">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Origin" class="form__label">State of Origin</label>
                        <select class="form-control form__input form__input--small" id="Origin">
                            <option>Delta State</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="local" class="form__label">L.G.A</label>
                        <select class="form-control form__input margin-bottom" id="local">
                            <option>Isoko North</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="count" class="form__label">Country</label>
                        <select class="form-control form__input margin-bottom" id="count">
                            <option>Nigeria</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="registration__text">
                <p>Not Mandatory</p>
            </div>

            <div class="row mt-5">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="net" class="form__label">Net Monthly Income</label>
                        <input type="text" class="form-control form__input form__input--small" id="net" placeholder="N200,000.00">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="amount" class="form__label">Amount Requested</label>
                        <input type="text" class="form-control form__input margin-bottom" id="amount" placeholder="N1,000,000.00">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="loan" class="form__label">Loan Tenor</label>
                        <input type="text" class="form-control form__input form__input--small" id="loan" placeholder="7 months">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="collateral" class="form__label">Collateral</label>
                        <input type="text" class="form-control form__input margin-bottom" id="collateral" placeholder="N100,000.00">
                    </div>
                </div>
            </div>
            <button class="btn__registration mt-5 mb-5">Register</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Registration"
    }
</script>

<style scoped>

</style>
