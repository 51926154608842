<template>
<div class="registration">
  <div class="registration__logo">
    <div class="container container-resize-2">
      <img src="images/logo.png" alt="logo" class="registration__logo--img">
    </div>
  </div>
  <div class="registration__title mt-5">
    <div class="container container-resize-2">
      <h1 class="registration__title--main">Registration Form</h1>
      <h2 class="registration__title--sub">Please fill out this section correctly.</h2>
    </div>
  </div>
  <div class="registration__detail mt-5">
    <div class="container container-resize-2">
      <h1>Personal Details</h1>
    </div>
  </div>

  <div class="container container-resize-2 mt-5 tabs-nav">
    <b-tabs content-class="mt-3" justified>
      <b-tab title="Basic Information" active>
        <b-row>
          <b-col cols="6">
            <input-forms label="First Name"
                         name="first_name"
                         type="text"
                         placeholder="Enter your first name"
            ></input-forms>
          </b-col>
          <b-col cols="6">
            <input-forms label="Middle Name"
                         name="father_name" type="text"
                         placeholder="Enter your middle name"
            ></input-forms>
          </b-col>
          <b-col cols="6">
            <input-forms label="Last Name"
                         name="last_name" type="text"
                         placeholder="Enter your last name"
            ></input-forms>
          </b-col>
          <b-col cols="6">
            <input-forms label="Email Address"
                         name="last_name" type="email"
                         placeholder="Enter your email address"
            ></input-forms>
          </b-col>
          <b-col cols="6">
            <select-input-forms label="Gender"
                                name="gender"
            >
              <option value="">Select Gender</option>
              <option value="m">Male</option>
              <option value="w">Female</option>
            </select-input-forms>
          </b-col>
          <b-col cols="6">
            <date-picker-form
                name="date"
                label="Date of birth"
                placeholder="DOB"
            ></date-picker-form>
          </b-col>
          <b-col cols="6">
            <currency-date-input></currency-date-input>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Second">

      </b-tab>
      <b-tab title="Very, very long title">

      </b-tab>
      <b-tab title="Disabled" >

      </b-tab>
    </b-tabs>


    <button class="btn__registration mt-5 mb-5">Register</button>
  </div>
</div>
</template>

<script>
import InputForms from "../forms/InputForms";
import SelectInputForms from "../forms/SelectInputForms";
import DatePickerForm from "../forms/DatePickerForm";
import CurrencyDateInput from "../forms/CurrencyDateInput";
export default {
  name: "Signup",
  components: {CurrencyDateInput, DatePickerForm, SelectInputForms, InputForms},
  data () {
    return {
      register: {}
    }
  },
  methods: {
    onSubmit() {

    },
    onReset () {

    }
  }
}
</script>

<style scoped>

</style>