<template>
  <div class="form-group">
    <label :for="$attrs.name" class="form__label">{{ label }}</label>
    <input type="text" ref="input"
           class="form-control form__input form__input--small"
           :name="$attrs.name"
           :id="$attrs.name"
           v-bind="$attrs"
           :placeholder="placeholder"
           v-model="innerValue">
  </div>
</template>

<script>
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';

export default {
  name: "DatePickerForm",
  props: {
    vid: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: [Date, String],
      default: null
    },

    multiple: {
      type: Boolean,
      default: false
    }

  },
  data: () => ({
    innerValue: '',
    fp: null,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },

    value () {
      // avoid the flatpickr instance if not initialized (prevent SSR crashes)
      if (!this.$fp) {
        return  this.value
      }

      const value = this.$fp.selectedDates;
      if (this.multiple) {
        return value;
      }

      return value[0];
    }
  },
  mounted () {
    if (this.value) {
      this.$refs.input.value = moment(this.value).format("YYYY Do MMM");
    }

    // initialize the flatpickr instance
    this.$fp = new Flatpickr(this.$refs.input);
  },
  beforeDestroy () {
    // don't forget to cleanup.
    this.$fp.destroy();
    delete this.$fp;
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>